import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Box } from 'theme-ui';
import { useEmbeddedCheckout } from '~/context/EmbeddedCheckoutContext';

const CheckoutPage = () => {
  const { checkout } = useEmbeddedCheckout();

  useEffect(() => {
    if (checkout) {
      checkout.mount('#checkout');
    } else {
      navigate('/');
    }
    return () => {
      checkout?.unmount();
    };
  }, [checkout]);

  return <Box sx={{ minHeight: '100vh', backgroundColor: 'black' }} id="checkout" />;
};

export default CheckoutPage;
